@import "@vmf/components/src/assets/style/_index.scss";




































































































































































































































































































































































































































































































































































































































































































.index-info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding-small;

    > .icon {
        flex: 0 0 auto;
        font-size: 26px;
        width: 30px;
        text-align: center;
        margin-right: $margin;
    }

    > .body {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        padding: $padding-small;
        border: 1px solid $color-border;
        border-radius: 5px;

        > .label {
            order: 1;
        }

        > .value {
            order: 0;
            font-weight: bold;
            font-size: 180%;
            color: $color-primary;
        }
    }
}
